.container {
    width: 60%;
    margin: 0 auto;
    background-color: #d6d6d6;
    border: 2px solid #000000;
    border-radius: 4px;
    margin-top: 8rem;

    @media (prefers-color-scheme: dark) {
        border: 2px solid #ffffff;
        background-color: #8b8b8b;
    }
}

h1 {
    text-decoration: underline;
    text-align: center;
}

.option {
    width: 50%;
    text-align: center;
    color: #000000;
    background-color: #ffffff;
    border: 2px solid black;
    margin: 0 auto;
    margin-bottom: .6rem;
    border-radius: 4px;

    &:hover {
        background-color: #9fbdff;
        transition: .6s all;
        cursor: pointer;
    }
}

.selected {
    background-color: #326ce5;
    color: #ffffff;
    text-decoration: outline 2px solid black;
}

.vote {
    display: block;
    width: 20%;
    text-decoration: none;
    background-color: #326ce5;
    color: #ffffff;
    border: 2px solid #000000;
    border-radius: 4px;
    padding: .5rem 0;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &:hover {
        cursor: pointer;
        background-color: #9fbdff;
        transition: .6s all;
    }
}