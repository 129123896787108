.header {
    display: flex;
    flex-flow: row wrap;
    background-color: #ffffff;
    border-bottom: solid black 2px;
    padding: 10px;
    margin-bottom: 2rem;

    @media (prefers-color-scheme: dark) {
        background-color: #333333;
    }

    .logo {
        width: 35px;
        height: 35px;
        color: #3f7afa;
    }

    .formmon {
        width: 100%;
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
        color: #326ce5;
        margin: 0 auto;
    }
}


.reg {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.register {
    background-color: #326ce5;
    padding: 4px;
    border: solid black 2px;
    border-radius: 15px;
}

.logButtons {
    width: 100%;
    display: flex;
    color: #326ce5;
    justify-content: flex-end;
}

.logout {
    text-decoration: underline;
    font-size: 1.2rem;
    margin-top: 4px;
}

.login {
    background-color: #ffffff;
    font-weight: bold;
    border: solid black 2px;
    border-radius: 15px;
    margin-left: 6px;
}