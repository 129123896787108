nav {
    display: flex;
    width: 70%;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    margin-top: 3rem;

    a {
        width: 10%;
        text-decoration: none;
        color: #000000;
        border-radius: 4px;
        justify-content: space-evenly;
        border-bottom: 1px solid #ffffff;
        margin: 0 auto;
        transition: .6s all;

        @media (prefers-color-scheme: dark) {
            color: #ffffff;
        }

        &:hover {
            background-color: #9fbdff;
        }
    }
}

.active {
    color: #ffffff;
    background-color: #326ce5;
    border-bottom: 2px solid #000000;

    &:hover {
        background-color: #326ce5;
    }
}




@media screen and (max-width: 780px) {
    nav a {
        width: 25%;
    }
}

@media screen and (max-width: 500px) {
    nav {
        width: 90%;
    }

    nav a {
        width: 20%;
    }
}