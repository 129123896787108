.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    background-color: #d6d6d6;
    border: solid black 2px;
    border-radius: 4px;
    margin: 0 auto;

    @media (prefers-color-scheme: dark) {
        background-color: #8b8b8b;
        border: 2px solid #ffffff;
    }
}

.h2 {
    width: 100%;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.formBody {
    width: 60%;
    margin: 0rem 1.5rem 1rem 1rem;
    display: flex;
    flex-flow: column wrap;
    margin: 0 auto;

    label {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 6px;
    }

    .question {
        width: 98%;
    }

    .answerContainer {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
    }

    input {
        display: flex;
        width: 73%;
        font-size: 1.5rem;
        margin-bottom: 8px;
        border: solid black 2px;
        border-radius: 4px;
        background-image: none;

        &:focus {
            outline: none;
            background-color: #caf0ff;
            transition: all .5s;
        }
    }

    .addAnswer {
        width: 22%;
        font-size: 1.5rem;
        text-align: center;
        margin: 0 auto;
        border: 2px solid #000000;

        &:hover {
            background-color: #9fbdff;
            transition: .6s all;
            cursor: pointer;
        }
    }

    .create {
        width: 40%;
        font-size: 1.5rem;
        background-color: #326ce5;
        color: #ffffff;
        margin: 2rem auto;
        padding: 1rem 0;
        border: 2px solid #ffffff;
        border-radius: 4px;

        &:hover {
            transition: .6s all;
            background-color: #9fbdff;
            cursor: pointer;
        }
    }
}

.multipleChoice {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    input {
        width: 20%;
        vertical-align: bottom;
    }
}

.listContainer {
    display: flex;
    flex-flow: column;
    width: 100%;

    div {
        display: flex;
        width: 100%;
        height: 23px;
        font-size: 1.5rem;
        color: black;
        border: 2px solid black;
        border-radius: 2px;
        margin-bottom: 4px;
        vertical-align: top;

        .answerList {
            width: 74%;
            background-color: #9fbdff;
            border: 0px;
            border-right: 2px solid black;
            align-items: center;
        }

        .deleteX {
            width: 25%;
            text-align: center;
            background-color: #fdadba;
            align-items: center;
            border: 0px;
            margin: 0 auto;

            :hover {
                cursor: pointer;
                background-color: #f67b8f;
                transition: .6s all;
            }

            span {
                width: 100%;
            }
        }
    }
}




@media screen and (max-width: 780px) {
    .form {
        width: 60%;
    }
}

@media screen and (max-width: 500px) {
    .form {
        width: 70%;
    }
}