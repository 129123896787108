.container {
    width: 70%;
    display: block;
    margin: 0 auto;

    h1 {
        margin-bottom: 40px;
    }

    .content1 {
        margin: 0 auto 40px auto;
        text-indent: 40px;
    }

    h2 {
        text-decoration: underline;
        text-align: center;    
        margin-bottom: 40px;
    }

    .content2 {
        text-indent: 40px;

        a {
            color: #0A66C2;
        }
    }
}