.feature {
    font-size: 3rem;
    text-align: center;
    margin: 0 auto;
}

.freePoll {
    display: block;
    justify-self: center;
    width: 10%;
    text-decoration: none;
    text-align: center;
    font-size: 2rem;
    color: #ffffff;
    background-color: #326ce5;
    transition: .6s all;
    border: 2px solid #000000;
    border-radius: 4px;
    padding: 1rem;
    margin: 0 auto;
    margin-top: 3rem;
    
    &:hover {
        background-color: #9fbdff;
    }
}

@media (prefers-color-scheme: dark) {
    .freePoll {
        border: 2px solid #ffffff;
    }
}

@media screen and (max-width: 780px) {
    .freePoll {
        width: 25%;
    }
}
