.container {

    h1 {
        text-align: center;
    }

    .content {
        margin-top: 40px;
        text-align: center;

        a {
            color: #0A66C2;
        }
    }
}