.container {
    width: 40%;
    padding-bottom: 3rem;
    margin: 50px auto 0px auto;
    border: 2px solid #000000;
    border-radius: 4px;
    background-color: #ffffff;

    @media (prefers-color-scheme: dark) {
        border: 2px solid #dbdbdb;
        background-color: #8b8b8b;
    }
}

.h1 {
    font-size: 3rem;
    text-align: center;
}

.h2 {
    font-weight: 700;
    text-align: center;
    margin: 2rem auto 2rem auto;
}

.data {
    display: flex;
    width: 80%;
    font-weight: 600;
    justify-content: space-between;
    background-color: #dbdbdb;
    color: #000000;
    margin: 0 auto;
    border: 2px solid #000000;

    @media (prefers-color-scheme: light) {
        .data {
            background-color: #eaeaea;
        }
    }

    .answers {
        display: inline-block;
        text-align: left;
        min-width: 20%;
        margin-left: 8px;
    }

    .votes {
        width: 20%;
        display: inline-block;
        text-align: center;
        color: #000000;
    }
}

.share {
    display: block;
    width: 20%;
    text-decoration: none;
    background-color: #326ce5;
    color: #ffffff;
    border: 2px solid #000000;
    border-radius: 4px;
    padding: .5rem 0;
    margin: 0 auto;
    margin-top: 4rem;

    &:hover {
        cursor: pointer;
        background-color: #9fbdff;
        transition: .6s all;
    }
}