body {
    background-color: #bcd0fb;
    color: #000000;
}


@media (prefers-color-scheme: dark) {
    body {
        background-color: #000b22;
        color: #ffffff;
    }
}